import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useGetChatMessages } from "../../../../data/chats/use-get-chat-messages";
import useCreateChat from '../../../../data/chats/createNewChat';
import useSendDataToDatabase from '../../../../data/prompts/useSendDataToDatabase';
import { WAKILI_TOKEN_URL, WAKILI_STREAM_URL, WAKILI_CHAT_STREAM_URL, WAKILI_CHAT_TOKEN_URL } from '../../../../data/constants';
import { useSSEStreaming } from "../../../../hooks/useSSEStreaming";
import FooterStreaming from "../Streaming/page";
import W from "../../../../img/Background.png"
import { useCollection } from "../../../../context/collectionContext";

// Import the new components
import DefaultPrompts from "./(components)/defaultPrompts";
import MessageList from "./(components)/messageList";
import ErrorMessage from "./(components)/errorMessage";
import WelcomeModal from "./(components)/welcomeModal";

interface Message {
  _id: string;
  promptText: string;
  response: string;
}

export default function ChatMessages() {
  const location = useLocation();
  const { chatId } = useParams<{ chatId?: string }>();
  const chatRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [currentPrompt, setCurrentPrompt] = useState<string>('');
  const [localMessages, setLocalMessages] = useState<Message[]>([]);
  const [showPrompts, setShowPrompts] = useState<boolean>(true);
  const { response, citationz, completed, startStreaming, errorMessage } = useSSEStreaming();
  const { sendDataToDatabase } = useSendDataToDatabase();
  const { data: messages, isLoading: isLoadingMessages, isError } = useGetChatMessages(chatId, !!chatId);
  const url = location.pathname.startsWith('/wakili/chatWithDoc/') ? '/citations/chatWithDoc' : '/citations';
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {collectionValue} = useCollection();


  const defaultPrompts = [
   
    "I am preparing for a civil lawsuit involving a breach of contract where the defendant is accused of not fulfilling their obligations under a signed agreement. The breach has resulted in significant financial losses for my client. Please identify and summarize all relevant Kenyan case law from the past ten years related to breaches of contract in the supply of goods, including any precedent cases where the court awarded damages for similar breaches.",
    "I am representing a community in Lamu County against a multinational company accused of environmental degradation due to illegal mining activities. Provide a detailed analysis of Kenyan environmental protection laws, focusing on the Environmental Management and Coordination Act, and any relevant case law that involves community land rights versus corporate activities. "

  ];

  useEffect(() => {
    if (messages) {
      setLocalMessages(messages);
    }
  }, [messages]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [localMessages, response]);

  useEffect(() => {
    if (completed && response && currentPrompt && messages) {
      const lastMessageId = messages.length > 0 ? messages[messages.length - 1]._id : Date.now().toString();
      const newMessage: Message = {
        _id:  lastMessageId,
        promptText: currentPrompt,
        response: response
      };
      setLocalMessages(prevMessages => [...prevMessages, newMessage]);
      setIsLoading(false);

      sendDataToDatabase({
        chatId: chatId || 'newChatId',
        promptText: currentPrompt,
        response: response,
        citations: citationz,
      });

      setCurrentPrompt('');
    }
  }, [completed, response, citationz]);

  useEffect(() => {
    const hasSeenModal = localStorage.getItem('hasSeenWakiliModal');
    if (hasSeenModal !== 'true') {
      setIsModalOpen(true);
      localStorage.setItem('hasSeenWakiliModal', 'true');
    }
  }, []);
  

  const handlePromptSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputValue.trim()) return;
    setIsLoading(true);
    setCurrentPrompt(inputValue);
  
    let currentChatId = chatId;
  
    const isChatWithDocPath = location.pathname.startsWith('/wakili/chatWithDoc');

    const context = isChatWithDocPath
      ? `${collectionValue.userId}_${collectionValue.name}` 
      : 'wakili';
    
    const requestBody = {
      id: 1,
      prompt: inputValue,
      chat_id: currentChatId,
      context: context,
    };

    //console.log('REQUEST BODY===>', requestBody);

    if(location.pathname.startsWith('/wakili/chatWithDoc')){
      startStreaming(requestBody, WAKILI_CHAT_TOKEN_URL, WAKILI_CHAT_STREAM_URL);
    } else {
      startStreaming(requestBody, WAKILI_TOKEN_URL, WAKILI_STREAM_URL);
    }
    
    setInputValue('');
  };
  
  const handleDefaultPromptClick = async (prompt: string) => {
    setShowPrompts(false);
    setIsLoading(true);
    setCurrentPrompt(prompt);

    const requestBody = {
      id: 1,
      prompt: prompt,
      chat_id: chatId,
      context:'wakili',
    };

    startStreaming(requestBody, WAKILI_TOKEN_URL, WAKILI_STREAM_URL);
  };

  const copyToClipboard = (message: string) => {
    navigator.clipboard.writeText(message);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const showImage = location.pathname.startsWith('/wakili/chatWithDoc');

  if (isLoadingMessages) return <div className="text-center py-4">Loading...</div>;
  if (isError) return <div className="text-center py-4 text-red-500">Error loading messages.</div>;

  return (
    <main className="ChatView_Section max-h-screen">
      {showImage && (
        <div className="absolute inset-0 flex items-center justify-center z-0 pointer-events-none">
          <img
            src={W}
            alt="Background Image"
            className="max-w-full max-h-[70vh] object-contain opacity-90"
          />
        </div>
      )}
       
      <div className="Clicked_Chats w-full z-10" ref={chatRef} style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        {(!localMessages || localMessages.length === 0) && !currentPrompt ? (
          <DefaultPrompts prompts={defaultPrompts} onPromptClick={handleDefaultPromptClick} />
        ) : (
          <MessageList
            messages={localMessages}
            currentPrompt={currentPrompt}
            response={response}
            chatId={chatId}
            url={url}
            onCopy={copyToClipboard}
            copied={copied}
          />
        )}
      </div>

      <FooterStreaming
        inputValue={inputValue}
        setInputValue={setInputValue}
        isLoading={isLoading}
        handlePromptSubmit={handlePromptSubmit}
        response={response}
      />

      <WelcomeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </main>
  );
}