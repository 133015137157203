import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import { LOGGED_USER } from "../../../../data/constants";
import { CreateCollectionInput } from "../../../../data/collections/types";
import { REACT_APP_BACKEND_URL } from "../../../../data/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

async function createCollection(
  data: CreateCollectionInput,
  setErrorMessage: (message: string | null) => void
) {
  const token = localStorage.getItem('token');
  if (!token) {
    setErrorMessage("User is not logged in.");
    return;
  }

  let loggedUserId = '';
  try {
    // Fetch logged user ID
    const response = await axios.get(`${REACT_APP_BACKEND_URL}/v1/auth/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    loggedUserId = response.data.id;
  } catch (error) {
    console.error('Error fetching user:', error);
    setErrorMessage("Unable to fetch the logged user.");
    return;
  }

  try {
    // Create collection
    const { data: { _id, name } } = await axios.post(
      `${REACT_APP_BACKEND_URL}/v1/collection/createCollection`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    // Create chat for the collection
    await axios.post(
      `${REACT_APP_BACKEND_URL}/v1/chat/createChat`,
      { specificChat: true, collectionId: _id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    
    // Create context in staging
    await axios.post(
      `https://ai-server.lawlyfy.ai/api/wakili/create_context`,
      {
        context: `${loggedUserId}_${name}`,
        hypercontext: "chat_with_your_docs",
      }
    );

    setErrorMessage(null); // Reset any existing error message
    return true;
  } catch (error) {
    console.error("Error creating collection/context:", error);
    setErrorMessage("An error occurred while creating the collection.");
    return;
  }
}

export default function AddCollection() {
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userId = LOGGED_USER() as string;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name) {
      setErrorMessage("Please enter a collection name.");
      return;
    }
    setLoading(true);
    setErrorMessage(null);

    try {
      const collectionCreated = await createCollection({ name, userId }, setErrorMessage);
      if (collectionCreated) {
        setName("");
        navigate('/wakili/chatWithDoc');
        window.location.reload(); // Reloading the page after success
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-slate-50 h-screen pt-10">
      <form onSubmit={handleSubmit} className="w-[90vw] md:w-[30vw] bg-white rounded-2xl m-auto px-8 py-6">
        <h1 className="text-center text-xl font-bold mb-2 text-[#615f5f]">Add New Collection</h1>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Collection Name"
          required
          className="w-full py-3 px-3 mb-4 border rounded text-xs shadow-sm"
        />
        <button type="submit" className="w-full bg-gradient-to-r from-linearBlue1 to-linearBlue2 text-white font-bold py-2 px-4 rounded">
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Add Collection"}
        </button>
        {errorMessage && <ErrorMessage message={errorMessage} />}
      </form>
    </div>
  );
}
