
import "../../pages/Home.css";
import Sure from "../../img/Sure.png"
import Blob from "../../img/Blob.png"
import LogoBlack from "../../img/Logo_Black.png";


export default function MainSection() {
  return (
    <>
      <div className="py-10 overflow-hidden sm:py-10 lg:pb-0 xl:pb-0 ">
        <div className="mx-auto px-4 sm:px-6 lg:px-8 lg:pr-0 max-w-7xl">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-3 lg:gap-y-20">
            <div className="mx-auto max-w-2xl lg:col-span-6  lg:max-w-none lg:pt-6 xl:col-span-6">
              <h1 className="text-3xl lg:text-[56px] lg:leading-tight tracking-tighter text-gray-800">
               <span className="font-extralight">Reclaim 10 Hours+ of Your Week with</span>  <span className='text-linearBlue1 font-semibold'>Lawlyfy</span>, <span className='font-normal text-gray-700'>Your AI Legal Research Assistant</span>
              </h1>

              <p className="mt-6 text-sm md:text-[18px] leading-loose text-gray-700 font-medium">


                <span className='text-linearBlue1 font-semibold'>Lawlyfy AI supercharges your legal workflows</span>, creating time
                for high-value strategic thinking and client interactions that truly set great lawyers apart!
              </p>

              <div className="mt-4 flex flex-col md:flex-row justify-center md:justify-normal gap-2 md:gap-4">
                <a
                  className="inline-flex justify-center rounded-full p-3 text-xs font-semibold outline-2 outline-offset-2 transition-colors text-white bg-gray-800 hover:bg-gradient-to-tr from-[#6ddcff] to-[#7f60f9] active:bg-gray-800  active:text-white/80 duration-300"
                  href="/login"
                >
                  Start 14-Day Free Trial
                </a>

                <a
                  className="inline-flex justify-center rounded-full p-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors text-gray-800 bg-white border border-gray-800 hover:bg-slate-100 active:bg-slate-200  active:text-gray-800/80"
                  href="https://calendly.com/sureantony-jguw/15min"
                >
                  Book a Demo
                </a>
              </div>

              <div className="py-4 flex justify-center md:justify-start items-center">
                <div>
                  <img
                    src={Sure}
                    alt="Bayes"
                    className="h-12 lg:h-16 mr-2 rounded-full"
                  />
                </div>

                <div className="text-sm leading-loose font-medium">
                  <p className="font-semibold">Talk to our team</p>
                  {/*  <p>15 min discovery call</p>*/}
                </div>
              </div>
            </div>

        

            <div className="relative mt-10 sm:mt-10 lg:col-span-6 lg:row-span-2 lg:mt-10 xl:col-span-6 overflow-hidden">
              <img src={Blob} alt="blob" className="absolute top-[-70px] h-full object-contain" />
            </div>


          </div>
        </div>
      </div>
      <div className="py-12 bg-gray-900 flex justify-center items-center mx-auto max-w-7xl">
        <img src={LogoBlack} alt="black logo" className="h-12"/>
      </div>
    </>
  );
}
