import { useQuery } from "@tanstack/react-query";
import { useFetchChatCitations } from "./fetch-chat-citations";
import { Citations } from "./types";

export function useGetChatCitations(messageId: string | undefined, enabled: boolean) {
  const fetchChatCitations = useFetchChatCitations(messageId);

  const query = useQuery<Citations[], Error>({
    queryKey: ["citations", messageId], 
    queryFn: fetchChatCitations,
    enabled: enabled, 
  });

  return {
    ...query,
    refetch: query.refetch, 
  };
}
