import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const REACT_APP_BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:4000/api";
export const REACT_APP_STAGING_URL =
  process.env.REACT_APP_STAGING_URL ||
  "https://ai-server.lawlyfy.ai/api/wakili_chat_with_your_docs";

export const LOGGED_USER = (): string | null => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  if (!token) return null;

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      // Token has expired, remove it and return null
      localStorage.removeItem("token");
      toast.info("Session expired. Please log in again.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      navigate("/login");
      return null;
    }

    return decodedToken.userId;
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};

export const WAKILI_TOKEN_URL =
  process.env.WAKILI_TOKEN_URL ||
  "https://ai-server.lawlyfy.ai/api/wakili_streaming_token";
export const WAKILI_STREAM_URL =
  process.env.WAKILI_STREAM_URL ||
  "https://ai-server.lawlyfy.ai/api/wakili_streaming";

export const WAKILI_CHAT_TOKEN_URL =
  process.env.WAKILI_TOKEN_URL ||
  "https://ai-server.lawlyfy.ai/api/wakili_chat_with_your_docs_streaming_token";
export const WAKILI_CHAT_STREAM_URL =
  process.env.WAKILI_STREAM_URL ||
  "https://ai-server.lawlyfy.ai/api/wakili_chat_with_your_docs_streaming";
