import React from 'react';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import Star from '../../../../../img/Star.png';
import rehypeRaw from 'rehype-raw';


interface Message {
  _id: string;
  promptText: string;
  response: string;
}

interface MessageListProps {
  messages: Message[];
  currentPrompt: string;
  response: string;
  chatId: string | undefined;
  url: string;
  onCopy: (message: string) => void;
  copied: boolean;
}

const MessageList: React.FC<MessageListProps> = ({ messages, currentPrompt, response, chatId, url, onCopy, copied }) => {
  return (
    <>
      {messages && messages.map((message) => (
        <div className='w-[98%] md:w-[85%] mx-auto' key={message._id}>
          <div className="Clicked_Chat">
            <div className="flex justify-start items-center gap-3">
              <div className="User w-10 h-9 rounded-full flex justify-center items-center bg-white text-xs">
                <FontAwesomeIcon icon={faUser} className="text-xs text-primary" />
              </div>
              <div className="Prompt w-full rounded-[16px] py-3 px-4 flex justify-between items-center leading-loose">
                <p className="text-xs font-medium">{message.promptText}</p>
              </div>
            </div>
            <div className="flex gap-3">
              <div className="User w-10 h-9 rounded-full mt-4 flex justify-center items-center bg-gradient-to-r from-linearBlue1 to-linearBlue2 text-xs">
                <img src={Star} alt="Star image" className='h-[16px] w-[16px]' style={{ filter: "brightness(0) invert(1)" }} />
              </div>
              <div className="Response w-full rounded-[16px] py-3 px-4 mt-4 text-xs">
                <ReactMarkdown className ='markdown' rehypePlugins={[rehypeRaw as any]}>{message.response}</ReactMarkdown>
                <div className="flex justify-between items-center mt-4">
                  <NavLink
                    to={`${url}/${chatId}/${message._id}`}
                    className="border border-linearBlue2 rounded-full text-xs px-2 hover:bg-linearBlue2 hover:border-none hover:text-white transition duration-300"
                  >
                    View Citations
                  </NavLink>
                  <button
                    onClick={() => onCopy(message.response)}
                    className="border border-linearBlue2 rounded-full text-xs px-2 hover:text-white hover:bg-linearBlue2 transition duration-300"
                  >
                    Copy <FontAwesomeIcon icon={copied ? faCheck : faCopy} className='ml-2' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {currentPrompt && (
        <div className='min-w-[98%] max-w-[98%] md:min-w-[85%] md:max-w-[85%] mx-auto'>
          <div className="Clicked_Chat">
            <div className="flex justify-start items-center gap-3">
              <div className="User w-10 h-9 rounded-full flex justify-center items-center bg-white text-xs">
                <FontAwesomeIcon icon={faUser} className="text-xs text-primary" />
              </div>
              <div className="Prompt w-full rounded-[16px] py-3 px-4 flex justify-between items-center leading-loose">
                <p className="text-xs font-medium">{currentPrompt}</p>
              </div>
            </div>
            <div className="flex gap-3">
              <div className="User w-10 h-9 rounded-full mt-4 flex justify-center items-center bg-gradient-to-r from-linearBlue1 to-linearBlue2 text-xs">
                <img src={Star} alt="Star image" className='h-[16px] w-[16px]' style={{ filter: "brightness(0) invert(1)" }} />
              </div>
              <div className="Response w-full rounded-[16px] py-3 px-4 mt-4 text-xs">
                <ReactMarkdown>{response}</ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MessageList;